import 'react-perfect-scrollbar/dist/css/styles.css';
import './styles/scss/custom.scss';
import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import 'src/mixins/chartjs';
import theme from 'src/theme';
import routes from 'src/routes';

import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import store from './redux/store/index';

const App = () => {
  const routing = useRoutes(routes);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        {routing}
        <ReduxToastr
          timeOut={5000}
          newestOnTop
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
      </ThemeProvider>
    </Provider>
  );
};

export default App;
