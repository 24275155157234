import { axiosApiInstance } from './ManageToken'
import { showToastr, toastrError } from '../utils/toast';

function getUserInSession(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/user/session`
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateUser(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/user/session`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has actualizado tu perfil.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function changePassword(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/changePassword`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has actualizado tu contaseña.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getColores(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/colores`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addColor(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/colores`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado un nuevo color.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteColor(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/colores/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El color ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateColor(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/colores/`+object_data.id,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El color ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getFamilias(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/familias`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getAllFamilias(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/allfamilias`,
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}
function getAllColores(){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/allcolores`,
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function deleteFamilia(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/familias/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El color ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getProductos(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/productos`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteProducto(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/productos/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El producto ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}


function getInventario(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/inventarios`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function addInventario(object_data){
  return axiosApiInstance.post(
      `${process.env.REACT_APP_API_URL}v2/inventarios`,
      object_data
    )
    .then(response => {
      if (response.status===201) {
          showToastr('success', 'Correcto.', 'Has agregado una nuevo elemento al inventario.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteInventario(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/inventarios/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El elemento ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function updateInventario(object_data){
  return axiosApiInstance.put(
      `${process.env.REACT_APP_API_URL}v2/inventarios/`+object_data.id,
      object_data
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'La elemento ha sido actualizado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getQuotes(page, limit){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/quotes`,
      { params: {
          page: page,
          limit: limit
      } }
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function getQuote(id){
  return axiosApiInstance.get(
      `${process.env.REACT_APP_API_URL}v2/quotes/`+id,
    )
    .then(response => {
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

function deleteQuote(id){
  return axiosApiInstance.delete(
      `${process.env.REACT_APP_API_URL}v2/quotes/`+id
    )
    .then(response => {
      if (response.status===200) {
          showToastr('success', 'Correcto.', 'El elemento ha sido eliminado.', 2500, true, true, 'top-right');
      }
      return response;
    })
    .catch(function (error) {
      toastrError(error);
    });
}

export const logedServices = {
  getUserInSession,
  updateUser,
  changePassword,
  getColores,
  addColor,
  deleteColor,
  updateColor,
  getFamilias,
  getAllFamilias,
  deleteFamilia,
  getAllColores,
  getProductos,
  deleteProducto,//
  getInventario,
  addInventario,
  deleteInventario,
  updateInventario,
  getQuotes,
  deleteQuote,
  getQuote
};
