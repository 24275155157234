import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { publicServices } from '../../services/publicServices';

const ValidarCode = () => {

  const { code } = useParams();
  const navigate = useNavigate();
  
  useEffect(() => {
    activarUsuario_(code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function activarUsuario_(code){
    publicServices.activarUsuario(code)
    .then(response => {
      if (response.status===200) {
        navigate('/auth/login', { replace: true });
      }
    })
    .catch(function (error) {
        return error;
    })
  }

  return ('');
};

export default ValidarCode;
