import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';

import {
  FormGroup,
  Label,
  Input,
  FormText,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import Select from 'react-select';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import Pagination from '@material-ui/lab/Pagination';
import { logedServices } from '../../services/logedServices';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ImageUploader from 'react-images-upload';
import { axiosApiInstanceFormData } from '../../services/ManageToken'
import { showToastr, toastrError } from '../../utils/toast';
import Loader from '../utilities/loader';

const Results = ({ className, ...rest }) => {
  const [selectedProductosIds, setSelectedProductosIds] = useState([]);
  const [productos, setProductos] = useState(null);
  const [productoActivo, setProductoActivo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [fichaTecnica, setFichaTecnica] = useState(null);
  const [image, setImage] = useState(null);
  const [progress, setProgress] = useState(null);
  const [familias, setFamilias] = useState([]);
  const [colores, setColores] = useState([]);
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedColorsUpdate, setSelectedColorsUpdate] = useState([]);
  const [page, setPage] = useState(1);

  function functionSetColorsUpdate(producto_) {
      var colores_=producto_.colores;
      var format = colores_.map(function(color_) {
          return { value: color_.color.id, label: color_.color.nombre };
      });
      setSelectedColorsUpdate(format);
  }

  function onImageChange(picture) {
    setImage(picture[0]);
  }
  const onFileChange = (event) => {
    setFichaTecnica(event.target.files[0]);
  };

  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };


  function handleRemove(id) {
    const newList = productos.data.filter((item) => {
      if (parseInt(item.id) !== parseInt(id)) {
        return item;
      }
    });
    const newArray = productos;
    newArray['data']=newList;
    setProductos(newArray);
  }

  function confimDelete(){
      logedServices.deleteProducto(productoActivo.id)
      .then(response => {
        if (response.status===200) {
          handleRemove(response.data.id);
          setOpen(false);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  useEffect(() => {
      getProductos(page);
      getFamilias();
      getColores();
  }, []);

  function getProductos(page_get){
      logedServices.getProductos(page_get, 10)
      .then(response => {
        if (response.status===200) {
          setProductos(response.data);
          setPage(page_get);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getFamilias(){
      logedServices.getAllFamilias()
      .then(response => {
        if (response.status===200) {
          setFamilias(response.data.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  function getColores(){
      logedServices.getAllColores()
      .then(response => {
        if (response.status===200) {
          var datas=response.data.data;
          var format = datas.map(function(color_) {
              return { value: color_.id, label: color_.nombre };
          });
          setColores(format);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleSelectAll = (event) => {
    let newSelectedProductosIds;

    if (event.target.checked) {
      newSelectedProductosIds = productos.data.map((producto) => producto.id);
    } else {
      newSelectedProductosIds = [];
    }

    setSelectedProductosIds(newSelectedProductosIds);
    console.log('newSelectedProductosIds==>')
    console.log(newSelectedProductosIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedProductosIds.indexOf(id);
    let newSelectedProductosIds = [];
    if (selectedIndex === -1) {
      newSelectedProductosIds = newSelectedProductosIds.concat(selectedProductosIds, id);
    } else if (selectedIndex === 0) {
      newSelectedProductosIds = newSelectedProductosIds.concat(selectedProductosIds.slice(1));
    } else if (selectedIndex === selectedProductosIds.length - 1) {
      newSelectedProductosIds = newSelectedProductosIds.concat(selectedProductosIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedProductosIds = newSelectedProductosIds.concat(
        selectedProductosIds.slice(0, selectedIndex),
        selectedProductosIds.slice(selectedIndex + 1)
      );
    }
    setSelectedProductosIds(newSelectedProductosIds);
  };

  const handlePageChange = (event, newPage) => {
    getProductos(newPage);
  };

  const handleChangeSelect = (selectedOptions) => {
    setSelectedColors(selectedOptions);
  };

  const handleChangeSelectUpdate = (selectedOptionsUpdate) => {
    setSelectedColorsUpdate(selectedOptionsUpdate);
  };

  const tableColores = (colors) => {
    if (colors.length>0) {
      return(
        <ListGroup type="unstyled">
          {colors.map((value) => {
            return (
              <ListGroupItem>{value.color.nombre}</ListGroupItem>
            );
          })}
        </ListGroup>
      );
    }else{
      return('');
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <CardHeader
              title="Productos"
            />
            <Grid container justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                style={{marginRight: "15px", marginBottom: "15px"}}
                onClick={() => { setOpenAdd(true);console.log(familias); }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          {productos!==null && productos.data.length>0 ? 
          <Grid
            item
            xs={12}
          >
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <PerfectScrollbar>
                    <Box minWidth={500}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedProductosIds.length === productos.data.length}
                                color="primary"
                                indeterminate={
                                  selectedProductosIds.length > 0
                                  && selectedProductosIds.length < productos.data.length
                                }
                                onChange={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell align="center">
                              Nombre
                            </TableCell>
                            <TableCell align="center">
                              SKU
                            </TableCell>
                            <TableCell align="center">
                              Familia
                            </TableCell>
                            <TableCell align="center">
                              Descripción
                            </TableCell>
                            <TableCell align="center">
                              Nota
                            </TableCell>
                            <TableCell align="center">
                              Fibra
                            </TableCell>
                            <TableCell align="center">
                              Tejido
                            </TableCell>
                            <TableCell align="center">
                              Peso
                            </TableCell>
                            <TableCell align="center">
                              Ancho
                            </TableCell>
                            <TableCell align="center">
                              Imagen
                            </TableCell>
                            <TableCell align="center">
                              Colores
                            </TableCell>
                            <TableCell align="center">
                              Ficha tecnica
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {productos.data.map((producto) => (
                            <TableRow
                              hover
                              key={producto.id}
                              selected={selectedProductosIds.indexOf(producto.id) !== -1}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedProductosIds.indexOf(producto.id) !== -1}
                                  onChange={(event) => handleSelectOne(event, producto.id)}
                                  value="true"
                                />
                              </TableCell>
                              <TableCell align="center">
                                {producto.nombre}
                              </TableCell>
                              <TableCell align="center">
                                {producto.sku}
                              </TableCell>
                              <TableCell align="center">
                                {producto.familia.nombre}
                              </TableCell>
                              <TableCell align="center">
                                {producto.descripcion}
                              </TableCell>
                              <TableCell align="center">
                                {producto.nota}
                              </TableCell>
                              <TableCell align="center">
                                {producto.fibra}
                              </TableCell>
                              <TableCell align="center">
                                {producto.tejido}
                              </TableCell>
                              <TableCell align="center">
                                {producto.peso}
                              </TableCell>
                              <TableCell align="center">
                                {producto.ancho}
                              </TableCell>
                              <TableCell align="center">
                                  {producto.imagen ?
                                    <a href={`${process.env.REACT_APP_API_URL}`+producto.imagen} target="_blank" rel="noreferrer">
                                        <img style={{ width: 115 }} src={`${process.env.REACT_APP_API_URL}`+producto.thumbnail} alt={producto.nombre} />
                                    </a>
                                  :''}   
                              </TableCell>
                              <TableCell align="center">
                                {tableColores(producto.colores)}
                              </TableCell>
                              <TableCell align="center">
                                  {producto.ficha_tecnica ?
                                    <a href={`${process.env.REACT_APP_API_URL}`+producto.ficha_tecnica} target="_blank" rel="noreferrer">
                                      Ficha tecnica
                                    </a>
                                  :''}                              
                              </TableCell>
                              <TableCell align="center">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  <Button onClick={() => { setProductoActivo(producto);functionSetColorsUpdate(producto);setOpenUpdate(true); }}>Editar</Button>
                                  <Button onClick={() => { setProductoActivo(producto);setOpen(true); }}>Eliminar</Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Grid container justifyContent="flex-end">
                    <Pagination count={productos.meta.last_page} page={productos.meta.current_page} onChange={handlePageChange} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          : ''}
        </Grid>
      </Card>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se eliminara el producto '{productoActivo!==null ? productoActivo.nombre : ''}''
            <strong className="block_ctrl">¿Deseas continuar?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => { confimDelete(); }} color="primary">
            Si, eliminar.
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAdd} onClose={handleCloseAdd} aria-labelledby="form-dialog-title">
        {progress===null?'':
          <Loader value={progress}/>
        }
        <Formik
          initialValues={{
            nombre: '',
            sku: '',
            familia_id: '',
            descripcion: '',
            nota: '',
            fibra: '',
            tejido: '',
            peso: '',
            ancho: '',
            backing: ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
            sku: Yup.string().max(100).required(),
            familia_id: Yup.string().required(),
            descripcion: Yup.string().max(1000),
            nota: Yup.string().max(1000),
            fibra: Yup.string().max(50),
            tejido: Yup.string().max(30),
            peso: Yup.string().max(10),
            ancho: Yup.string().max(20),
            backing: Yup.string().max(40)
          })}
          onSubmit={(values) => {
            setDisabled(true);
            var formData = new FormData(); 
            if (image) {
              formData.append('imagen', image, image.name);
            }
            if (fichaTecnica) {
              formData.append('ficha_tecnica', fichaTecnica, fichaTecnica.name);
            }
            if (selectedColors.length>0) {
              var toSend = JSON.stringify(Object.assign({}, selectedColors));
              formData.append('selected_colors', toSend);
            }
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/productos`,
                formData,
                {
                  onUploadProgress: progressEvent => {
                    let prog=Math.round(progressEvent.loaded / progressEvent.total * 100);
                    if (prog!==100) {
                      setProgress(prog);
                    }
                  }
                }
              )
              .then(response => {
                if (response.status===201) {
                    showToastr('success', 'Correcto.', 'Has agregado un nuevo producto.', 2500, true, true, 'top-right');
                    setProgress(100);
                    getProductos(1);
                    setOpenAdd(false);
                    setFichaTecnica(null)
                    setImage(null)
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setProgress(null);
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Nuevo producto"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.descripcion && errors.descripcion)}
                              fullWidth
                              helperText={touched.descripcion && errors.descripcion}
                              label="Descripción"
                              margin="normal"
                              name="descripcion"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.descripcion}
                              variant="outlined"
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nota && errors.nota)}
                              fullWidth
                              helperText={touched.nota && errors.nota}
                              label="Nota"
                              margin="normal"
                              name="nota"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nota}
                              variant="outlined"
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pr-md-1"
                          >
                            <TextField
                              error={Boolean(touched.fibra && errors.fibra)}
                              fullWidth
                              helperText={touched.fibra && errors.fibra}
                              label="Fibra"
                              margin="normal"
                              name="fibra"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.fibra}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pl-md-1"
                          >
                            <TextField
                              error={Boolean(touched.tejido && errors.tejido)}
                              fullWidth
                              helperText={touched.tejido && errors.tejido}
                              label="Tejido"
                              margin="normal"
                              name="tejido"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.tejido}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pr-md-1"
                          >
                            <TextField
                              error={Boolean(touched.peso && errors.peso)}
                              fullWidth
                              helperText={touched.peso && errors.peso}
                              label="Peso"
                              margin="normal"
                              name="peso"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.peso}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pl-md-1"
                          >
                            <TextField
                              error={Boolean(touched.ancho && errors.ancho)}
                              fullWidth
                              helperText={touched.ancho && errors.ancho}
                              label="Ancho"
                              margin="normal"
                              name="ancho"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.ancho}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pr-md-1"
                          >
                            <TextField
                              error={Boolean(touched.backing && errors.backing)}
                              fullWidth
                              helperText={touched.backing && errors.backing}
                              label="Backing"
                              margin="normal"
                              name="backing"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.backing}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pl-md-1"
                          >
                            <TextField
                              error={Boolean(touched.sku && errors.sku)}
                              fullWidth
                              helperText={touched.sku && errors.sku}
                              label="Sku"
                              margin="normal"
                              name="sku"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.sku}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="mb-4 mt-3"
                          >
                            <TextField
                              fullWidth
                              label="Familia"
                              name="familia_id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              select
                              SelectProps={{ native: true }}
                              error={Boolean(touched.familia_id && errors.familia_id)}
                              helperText={touched.familia_id && errors.familia_id}
                              value={values.familia_id}
                              variant="outlined"
                            >
                              <option hidden
                                value=''
                              ></option>
                              {familias.map((familia) => (
                                <option
                                  key={familia.id}
                                  value={familia.id}
                                >{familia.nombre}</option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="mb-4"
                          >
                            <Select options={colores} isMulti={true} onChange={handleChangeSelect} placeholder="Colores"/>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <FormGroup>
                              <Label for="exampleFile">Catalogo</Label>
                              <Input type="file" name="file" onChange={onFileChange} accept=".pdf"/>
                              <FormText color="muted">Archivos permitidos: PDF</FormText>
                            </FormGroup>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <ImageUploader
                              withIcon={true}
                              buttonText='Selecciona una imagen'
                              onChange={onImageChange}
                              imgExtension={['.jpg', '.JPG','.jpeg', '.png', '.webp', '.svg']}
                              maxFileSize={5242880}
                              singleImage={true}
                              withPreview={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAdd} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Agregar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>


      <Dialog open={openUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            nombre: (productoActivo!==null && productoActivo.nombre!==null) ? productoActivo.nombre : ' ',
            sku: (productoActivo!==null && productoActivo.sku!==null) ? productoActivo.sku : ' ',
            id: (productoActivo!==null && productoActivo.id!==null) ? productoActivo.id : ' ',
            familia_id: (productoActivo!==null && productoActivo.familia_id!==null) ? productoActivo.familia_id : ' ',
            descripcion: (productoActivo!==null && productoActivo.descripcion!==null) ? productoActivo.descripcion : ' ',
            nota: (productoActivo!==null && productoActivo.nota!==null) ? productoActivo.nota : ' ',
            fibra: (productoActivo!==null && productoActivo.fibra!==null) ? productoActivo.fibra : ' ',
            tejido: (productoActivo!==null && productoActivo.tejido!==null) ? productoActivo.tejido : ' ',
            peso: (productoActivo!==null && productoActivo.peso!==null) ? productoActivo.peso : ' ',
            ancho: (productoActivo!==null && productoActivo.ancho!==null) ? productoActivo.ancho : ' ',
            backing: (productoActivo!==null && productoActivo.backing!==null) ? productoActivo.backing : ' '
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
            sku: Yup.string().required(),
            familia_id: Yup.string().required(),
            descripcion: Yup.string().nullable(),
            nota: Yup.string().nullable(),
            fibra: Yup.string().max(50),
            tejido: Yup.string().max(30),
            peso: Yup.string().max(10),
            ancho: Yup.string().max(20),
            backing: Yup.string().max(20)
          })}
          onSubmit={(values) => {
            setDisabled(true);
            var formData = new FormData(); 
            if (image) {
              formData.append('imagen', image, image.name);
            }
            if (fichaTecnica) {
              formData.append('ficha_tecnica', fichaTecnica, fichaTecnica.name);
            }
            if (selectedColorsUpdate.length>0) {
              var toSend = JSON.stringify(Object.assign({}, selectedColorsUpdate));
              formData.append('selected_colors', toSend);
            }
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/productos/update`,
                formData
              )
              .then(response => {
                if (response.status===200) {
                    showToastr('success', 'Correcto.', 'El producto ha sido actualizado.', 2500, true, true, 'top-right');
                    getProductos(page);
                    setOpenUpdate(false);
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setDisabled(false);
                setFichaTecnica(null)
                setImage(null)
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Actualizando producto"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.descripcion && errors.descripcion)}
                              fullWidth
                              helperText={touched.descripcion && errors.descripcion}
                              label="Descripción"
                              margin="normal"
                              name="descripcion"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.descripcion}
                              variant="outlined"
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nota && errors.nota)}
                              fullWidth
                              helperText={touched.nota && errors.nota}
                              label="Nota"
                              margin="normal"
                              name="nota"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nota}
                              variant="outlined"
                              multiline
                              rows={4}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pr-md-1"
                          >
                            <TextField
                              error={Boolean(touched.fibra && errors.fibra)}
                              fullWidth
                              helperText={touched.fibra && errors.fibra}
                              label="Fibra"
                              margin="normal"
                              name="fibra"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.fibra}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pl-md-1"
                          >
                            <TextField
                              error={Boolean(touched.tejido && errors.tejido)}
                              fullWidth
                              helperText={touched.tejido && errors.tejido}
                              label="Tejido"
                              margin="normal"
                              name="tejido"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.tejido}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pr-md-1"
                          >
                            <TextField
                              error={Boolean(touched.peso && errors.peso)}
                              fullWidth
                              helperText={touched.peso && errors.peso}
                              label="Peso"
                              margin="normal"
                              name="peso"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.peso}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pl-md-1"
                          >
                            <TextField
                              error={Boolean(touched.ancho && errors.ancho)}
                              fullWidth
                              helperText={touched.ancho && errors.ancho}
                              label="Ancho"
                              margin="normal"
                              name="ancho"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.ancho}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pr-md-1"
                          >
                            <TextField
                              error={Boolean(touched.backing && errors.backing)}
                              fullWidth
                              helperText={touched.backing && errors.backing}
                              label="Backing"
                              margin="normal"
                              name="backing"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.backing}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="pl-md-1"
                          >
                            <TextField
                              error={Boolean(touched.sku && errors.sku)}
                              fullWidth
                              helperText={touched.sku && errors.sku}
                              label="Sku"
                              margin="normal"
                              name="sku"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.sku}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="mb-4 mt-3"
                          >
                            <TextField
                              fullWidth
                              label="Familia"
                              name="familia_id"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              select
                              SelectProps={{ native: true }}
                              error={Boolean(touched.familia_id && errors.familia_id)}
                              helperText={touched.familia_id && errors.familia_id}
                              value={values.familia_id}
                              variant="outlined"
                            >
                              <option hidden
                                value=''
                              ></option>
                              {familias.map((familia) => (
                                <option
                                  key={familia.id}
                                  value={familia.id}
                                >{familia.nombre}</option>
                              ))}
                            </TextField>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className="mb-4"
                          >
                            <Select options={colores} value={selectedColorsUpdate} isMulti={true} onChange={handleChangeSelectUpdate} placeholder="Colores"/>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="text-center"
                          >
                            {productoActivo.ficha_tecnica?
                              <React.Fragment>
                                <span className="block_ctrl">Ficha actual:</span>
                                <Button variant="contained" color="default" href={`${process.env.REACT_APP_API_URL}`+productoActivo.ficha_tecnica} target="_blank">
                                  <PictureAsPdfIcon/>
                                </Button>
                              </React.Fragment>
                            :''}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="text-center"
                          >
                            <FormGroup>
                              <Label for="exampleFile">{productoActivo.ficha_tecnica?'Cambiar ficha tecnica':'Ficha tecnica'}</Label>
                              <Input type="file" name="file" onChange={onFileChange} accept=".pdf"/>
                              <FormText color="muted">Archivos permitidos: PDF</FormText>
                            </FormGroup>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="text-center"
                          >
                            {productoActivo.imagen?
                              <React.Fragment>
                                <span className="block_ctrl">Imagen actual:</span>
                                <Button variant="contained" color="default" href={`${process.env.REACT_APP_API_URL}`+productoActivo.imagen} target="_blank">
                                  <img style={{ width: 115 }} src={`${process.env.REACT_APP_API_URL}`+productoActivo.thumbnail} alt={productoActivo.nombre} />
                                </Button>
                              </React.Fragment>
                            :''}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="text-center"
                          >
                            <ImageUploader
                              withIcon={true}
                              buttonText='Cambiar imagen'
                              onChange={onImageChange}
                              imgExtension={['.jpg', '.JPG','.jpeg', '.png', '.webp', '.svg']}
                              maxFileSize={5242880}
                              singleImage={true}
                              withPreview={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUpdate} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Actualizar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>



    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;