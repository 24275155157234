import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import validator from 'validator';
import { logedServices } from '../../../services/logedServices';

Yup.setLocale({
  mixed: {
    required: 'Esta campo es requerido',
  },
  string: {
    required: 'Esta campo es requerido',
    max: 'El texto es demasiado largo',
    min: 'El texto es demasiado corto',
  },
  number: {
    integer: 'Solo se aceptan datos numericos'
  }
})

const User = (props) => {

  const profile = props.propProfile;
  const setProfile = props.propSetProfile;
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
    { profile !==null ?
      <Card>
        <CardContent>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {profile.user.name}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {profile.user.email}
            </Typography>
          </Box>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            fullWidth
            variant="text"
            onClick={handleClickOpen}
          >
            {(profile!==null && profile.user.pass !== null)?'Cambiar contraseña':'Crear contraseña'}
          </Button>
        </CardActions>
      <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth={true} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            password: '',
            new_password: '',
            confirm: '',
          }}
          validationSchema={Yup.object().shape({
            password: (profile!==null && profile.user.pass !== null) ? Yup.string().required() : null,
            new_password: Yup.string().max(20).required('La contraseña es requerida').test('test-name', 'La contraseña no cumple las condiciones seguras', 
              function(value) {
                if (value!==undefined) {
                  if (validator.isStrongPassword(value, {
                    minLength: 8, minLowercase: 1,
                    minUppercase: 1, minNumbers: 1, minSymbols: 1
                  })) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }),
            confirm: Yup.string().required('La contraseña es requerida').oneOf([Yup.ref('new_password'), null], 'La contraseña no coincide')
          })}
          onSubmit={(values) => {
            logedServices.changePassword(values)
            .then(response => {
              if (response.status===201) {
                console.log('response.data.profile');
                console.log(response.data.profile);
                setProfile(response.data.profile);
                setOpen(false);
              }
            })
            .catch(function (error) {
                return error;
            })
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              autoComplete="off"
              onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}
              style={{
                display: 'inline-block',
                width: '100%',
              }}
            >
              <DialogTitle id="form-dialog-title">{(profile!==null && profile.user.pass !== null) ? 'Cambiar contraseña' : 'Crear contraseña' }</DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={3}
                >
                  {(profile!==null && profile.user.pass !== null) ?
                    <Grid
                      item
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        name="password"
                        onChange={(e) => {handleChange(e); }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        value={values.password}
                        variant="outlined"
                        label="Contraseña actual"
                        type="password"
                      />
                    </Grid>
                  :''}
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      name="new_password"
                      onChange={(e) => {handleChange(e); }}
                      error={Boolean(touched.new_password && errors.new_password)}
                      helperText={touched.new_password && errors.new_password}
                      onBlur={handleBlur}
                      value={values.new_password}
                      variant="outlined"
                      label="Nueva contraseña"
                      type="password"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <TextField
                      fullWidth
                      name="confirm"
                      onChange={(e) => {handleChange(e); }}
                      error={Boolean(touched.confirm && errors.confirm)}
                      helperText={touched.confirm && errors.confirm}
                      onBlur={handleBlur}
                      value={values.confirm}
                      variant="outlined"
                      label="Repite la nueva contraseña"
                      type="password"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="default">
                  Cancelar
                </Button>
                <Button type="submit" color="primary">
                  Cambiar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>

      </Dialog>


      </Card>
    :''}
    </React.Fragment>
  );
};

User.propTypes = {
  className: PropTypes.string
};

export default User;
