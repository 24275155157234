import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { logedServices } from '../../../services/logedServices';


const ProfileDetails = (props) => {

  const profile = props.propProfile;
  const setProfile = props.propSetProfile;

  const [disabled, setDisabled] = useState(false);

  return (
    <React.Fragment>
    {profile!==null ?
      <Formik
        initialValues={{
          name: profile.user.name,
          email: profile.user.email
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().max(150),
          email: Yup.string().max(150),
        })}
        onSubmit={(values) => {
          setDisabled(true);
          logedServices.updateUser(values)
            .then(response => {
              if (response.status === 201) {
                setProfile(response.data);
              }
            })
            .catch(function (error) {
            }).then(function () {
              setDisabled(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form
            autoComplete="off"
            noValidate
            onSubmit={handleSubmit}
          >
            <Card>
              <CardHeader
                subheader="Puedes editar la información"
                title="Mi perfil"
              />
              <Divider />
              <CardContent>
                <Grid
                  item
                  xs={12}
                >
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.name && errors.name)}
                        fullWidth
                        helperText={touched.name && errors.name}
                        label="Nombre"
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Email"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>              
              <Divider />
              <Box
                display="flex"
                justifyContent="flex-end"
                p={2}
              >
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={disabled}
                >
                  Guardar
                </Button>
              </Box>
            </Card>
          </form>
        )}
      </Formik>
    : ''}
    </React.Fragment>
  );
};

export default ProfileDetails;
