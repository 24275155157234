import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';

import {
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import Pagination from '@material-ui/lab/Pagination';
import { logedServices } from '../../services/logedServices';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { axiosApiInstanceFormData } from '../../services/ManageToken'
import { showToastr, toastrError } from '../../utils/toast';
import Loader from '../utilities/loader';

const Results = ({ className, ...rest }) => {
  const [selectedFamiliasIds, setSelectedFamiliasIds] = useState([]);
  const [familias, setFamilias] = useState(null);
  const [familiaActiva, setFamiliaActiva] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [fileTwo, setFileTwo] = useState(null);
  const [progress, setProgress] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [openAdd, setOpenAdd] = React.useState(false);
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };


  function handleRemove(id) {
    const newList = familias.data.filter((item) => {
      if (parseInt(item.id) !== parseInt(id)) {
        return item;
      }
      return false;
    });
    const newArray = familias;
    newArray['data']=newList;
    setFamilias(newArray);
  }

  function confimDelete(){
      logedServices.deleteFamilia(familiaActiva.id)
      .then(response => {
        if (response.status===200) {
          handleRemove(response.data.id);
          setOpen(false);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  useEffect(() => {
      getFamilias(1);
  }, []);

  function getFamilias(page){
      logedServices.getFamilias(page, 10)
      .then(response => {
        if (response.status===200) {
          setFamilias(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleSelectAll = (event) => {
    let newSelectedFamiliasIds;

    if (event.target.checked) {
      newSelectedFamiliasIds = familias.data.map((familia) => familia.id);
    } else {
      newSelectedFamiliasIds = [];
    }

    setSelectedFamiliasIds(newSelectedFamiliasIds);
    console.log('newSelectedFamiliasIds==>')
    console.log(newSelectedFamiliasIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedFamiliasIds.indexOf(id);
    let newSelectedFamiliasIds = [];

    if (selectedIndex === -1) {
      newSelectedFamiliasIds = newSelectedFamiliasIds.concat(selectedFamiliasIds, id);
    } else if (selectedIndex === 0) {
      newSelectedFamiliasIds = newSelectedFamiliasIds.concat(selectedFamiliasIds.slice(1));
    } else if (selectedIndex === selectedFamiliasIds.length - 1) {
      newSelectedFamiliasIds = newSelectedFamiliasIds.concat(selectedFamiliasIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedFamiliasIds = newSelectedFamiliasIds.concat(
        selectedFamiliasIds.slice(0, selectedIndex),
        selectedFamiliasIds.slice(selectedIndex + 1)
      );
    }

    setSelectedFamiliasIds(newSelectedFamiliasIds);
  };

  const handlePageChange = (event, newPage) => {
    getFamilias(newPage);
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const onFileChangeTwo = (event) => {
    setFileTwo(event.target.files[0]);
  };

  const tiposEntrega = ['Entrega Inmediata','Sobre pedido'];

  return (
    <React.Fragment>
      <Card>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <CardHeader
              title="Colores"
            />
            <Grid container justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                style={{marginRight: "15px", marginBottom: "15px"}}
                onClick={() => { setOpenAdd(true); }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          {familias!==null && familias.data.length>0 ? 
          <Grid
            item
            xs={12}
          >
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <PerfectScrollbar>
                    <Box minWidth={500}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedFamiliasIds.length === familias.data.length}
                                color="primary"
                                indeterminate={
                                  selectedFamiliasIds.length > 0
                                  && selectedFamiliasIds.length < familias.data.length
                                }
                                onChange={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell align="center">
                              Nombre
                            </TableCell>
                            <TableCell align="center">
                              SKU
                            </TableCell>
                            <TableCell align="center">
                              Orden
                            </TableCell>
                            <TableCell align="center">
                              Tipo de entrega
                            </TableCell>
                            <TableCell align="center">
                              Catalogo
                            </TableCell>
                            <TableCell align="center">
                              Prueba de laboratorio
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {familias.data.map((familia) => (
                            <TableRow
                              hover
                              key={familia.id}
                              selected={selectedFamiliasIds.indexOf(familia.id) !== -1}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedFamiliasIds.indexOf(familia.id) !== -1}
                                  onChange={(event) => handleSelectOne(event, familia.id)}
                                  value="true"
                                />
                              </TableCell>
                              <TableCell align="center">
                                {familia.nombre}
                              </TableCell>
                              <TableCell align="center">
                                {familia.sku}
                              </TableCell>
                              <TableCell align="center">
                                {familia.orden}
                              </TableCell>
                              <TableCell align="center">
                                {familia.entrega}
                              </TableCell>
                              <TableCell align="center">
                                  {familia.catalogo ?
                                    <a href={`${process.env.REACT_APP_API_URL}`+familia.catalogo} target="_blank" rel="noreferrer">
                                      Catalogo
                                    </a>
                                  :''}
                              </TableCell>
                              <TableCell align="center">
                                  {familia.prueba_laboratorio ?
                                    <a href={`${process.env.REACT_APP_API_URL}`+familia.prueba_laboratorio} target="_blank" rel="noreferrer">
                                      Prueba de laboratorio
                                    </a>
                                  :''}
                              </TableCell>
                              <TableCell align="center">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  <Button onClick={() => { setFamiliaActiva(familia);setOpenUpdate(true); }}>Editar</Button>
                                  <Button onClick={() => { setFamiliaActiva(familia);setOpen(true); }}>Eliminar</Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Grid container justifyContent="flex-end">
                    <Pagination count={familias.meta.last_page} page={familias.meta.current_page} onChange={handlePageChange} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          : ''}
        </Grid>
      </Card>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Se eliminara la familia '{familiaActiva!==null ? familiaActiva.nombre : ''}''
            <strong className="block_ctrl">¿Deseas continuar?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => { confimDelete(); }} color="primary">
            Si, eliminar.
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAdd} onClose={handleCloseAdd} aria-labelledby="form-dialog-title">
        {progress===null?'':
          <Loader value={progress}/>
        }
        <Formik
          initialValues={{
            nombre: '',
            sku: '',
            orden: '',
            entrega: ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
            sku: Yup.string().max(50).required(),
            orden: Yup.number().integer(),
            entrega: Yup.string().max(100).required()
          })}
          onSubmit={(values) => {
            setDisabled(true);
            var formData = new FormData(); 
            if (file) {
              formData.append('file', file, file.name);
            }
            if (fileTwo) {
              formData.append('filetwo', fileTwo, fileTwo.name);
            }
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/familias`,
                formData,
                {
                  onUploadProgress: progressEvent => {
                    let prog=Math.round(progressEvent.loaded / progressEvent.total * 100);
                    if (prog!==100) {
                      setProgress(prog);
                    }
                  }
                }
              )
              .then(response => {
                if (response.status===201) {
                    showToastr('success', 'Correcto.', 'Has agregado una nueva familia.', 2500, true, true, 'top-right');
                    setProgress(100);
                    getFamilias(1);
                    setOpenAdd(false);
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setProgress(null);
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Nueva familia"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.sku && errors.sku)}
                              fullWidth
                              helperText={touched.sku && errors.sku}
                              label="SKU"
                              margin="normal"
                              name="sku"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.sku}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.orden && errors.orden)}
                              fullWidth
                              helperText={touched.orden && errors.orden}
                              label="Orden"
                              margin="normal"
                              name="orden"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.orden}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="my-3"
                          >
                            <TextField
                              fullWidth
                              label="Entrega"
                              name="entrega"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              select
                              SelectProps={{ native: true }}
                              error={Boolean(touched.entrega && errors.entrega)}
                              helperText={touched.entrega && errors.entrega}
                              value={values.entrega}
                              variant="outlined"
                            >
                              <option hidden
                                value=''
                              ></option>
                              {tiposEntrega.map((type) => (
                                <option
                                  key={type}
                                  value={type}
                                >{type}</option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <FormGroup>
                              <Label for="file">Catalogo</Label>
                              <Input type="file" name="file" onChange={onFileChange} accept=".pdf"/>
                              <FormText color="muted">Archivos permitidos: PDF</FormText>
                            </FormGroup>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <FormGroup>
                              <Label for="filetwo">Prueba de laboratorio</Label>
                              <Input type="file" name="filetwo" onChange={onFileChangeTwo} accept=".pdf"/>
                              <FormText color="muted">Archivos permitidos: PDF</FormText>
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAdd} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Agregar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>


      <Dialog open={openUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            nombre: familiaActiva!==null ? familiaActiva.nombre : '',
            sku: familiaActiva!==null ? familiaActiva.sku : '',
            orden: familiaActiva!==null ? familiaActiva.orden : '',
            entrega: familiaActiva!==null ? familiaActiva.entrega : '',
            id: familiaActiva!==null ? familiaActiva.id : ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
            sku: Yup.string().max(50).required(),
            orden: Yup.number().integer().nullable(true),
            entrega: Yup.string().max(100).required()
          })}
          onSubmit={(values) => {
            setDisabled(true);
            var formData = new FormData();
            if (file) {
              formData.append('file', file, file.name);
            }
            if (fileTwo) {
              formData.append('filetwo', fileTwo, fileTwo.name);
            }
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/familias/update`,
                formData
              )
              .then(response => {
                if (response.status===200) {
                    showToastr('success', 'Correcto.', 'La familia ha sido actualizada.', 2500, true, true, 'top-right');
                    getFamilias(1);
                    setOpenUpdate(false);
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setFile(null);
                setFileTwo(null);
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Actualizando familia"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.sku && errors.sku)}
                              fullWidth
                              helperText={touched.sku && errors.sku}
                              label="SKU"
                              margin="normal"
                              name="sku"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.sku}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.orden && errors.orden)}
                              fullWidth
                              helperText={touched.orden && errors.orden}
                              label="Orden"
                              margin="normal"
                              name="orden"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.orden}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="my-3"
                          >
                            <TextField
                              fullWidth
                              label="Entrega"
                              name="entrega"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              select
                              SelectProps={{ native: true }}
                              error={Boolean(touched.entrega && errors.entrega)}
                              helperText={touched.entrega && errors.entrega}
                              value={values.entrega}
                              variant="outlined"
                            >
                              <option hidden
                                value=''
                              ></option>
                              {tiposEntrega.map((type) => (
                                <option
                                  key={type}
                                  value={type}
                                >{type}</option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <FormGroup>
                              <Label for="exampleFile">{familiaActiva.catalogo?'Nuevo catalogo':'Catalogo'}</Label>
                              <Input type="file" name="file" onChange={onFileChange} accept=".pdf"/>
                              <FormText color="muted">Archivos permitidos: PDF</FormText>
                            </FormGroup>
                            {familiaActiva.catalogo?
                              <Button variant="contained" color="default" href={`${process.env.REACT_APP_API_URL}`+familiaActiva.catalogo} target="_blank">
                                <PictureAsPdfIcon/>
                              </Button>
                            :''}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <FormGroup>
                              <Label for="filetwo">{familiaActiva.prueba_laboratorio?'Nueva prueba de laboratorio':'Prueba de laboratorio'}</Label>
                              <Input type="file" name="filetwo" onChange={onFileChangeTwo} accept=".pdf"/>
                              <FormText color="muted">Archivos permitidos: PDF</FormText>
                            </FormGroup>
                            {familiaActiva.prueba_laboratorio?
                              <Button variant="contained" color="default" href={`${process.env.REACT_APP_API_URL}`+familiaActiva.prueba_laboratorio} target="_blank">
                                <PictureAsPdfIcon/>
                              </Button>
                            :''}
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUpdate} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Actualizar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>



    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
