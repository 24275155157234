import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = (props) => {

  return (
    <div className="loaderContainer">
      <div className="container h-100">
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-auto">
            <CircularProgress variant="determinate" {...props} />
            <span>{`${Math.round(props.value)}%`}</span>
          </div>
        </div>
      </div>
    </div>
  );

};

export default Loader;