import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  CardHeader,
  Divider,
  CardContent,
  TextField
} from '@material-ui/core';
import {
  FormGroup,
  Label,
  Input,
  FormText
} from "reactstrap";
import Pagination from '@material-ui/lab/Pagination';
import { logedServices } from '../../services/logedServices';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { axiosApiInstanceFormData } from '../../services/ManageToken'
import { showToastr, toastrError } from '../../utils/toast';
import ImageUploader from 'react-images-upload';

const Results = ({ className, ...rest }) => {
  const [selectedColoresIds, setSelectedColoresIds] = useState([]);
  const [colores, setColores] = useState(null);
  const [colorActivo, setColorActivo] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [progress, setProgress] = useState(null);
  const [image, setImage] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [openColor, setOpenColor] = React.useState(false);
  const handleCloseAdd = () => {
    setOpenColor(false);
  };

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };


  function handleRemove(id) {
    const newList = colores.data.filter((item) => {
      if (parseInt(item.id) !== parseInt(id)) {
        return item;
      }
      return false;
    });
    const newArray = colores;
    newArray['data']=newList;
    setColores(newArray);
  }

  function confimDelete(){
      logedServices.deleteColor(colorActivo.id)
      .then(response => {
        if (response.status===200) {
          handleRemove(response.data.id);
          setOpen(false);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  useEffect(() => {
      getColores(1);
  }, []);

  function getColores(page){
      logedServices.getColores(page, 10)
      .then(response => {
        if (response.status===200) {
          setColores(response.data);
        }
      })
      .catch(function (error) {
          return error;
      })
  }

  const handleSelectAll = (event) => {
    let newSelectedColoresIds;

    if (event.target.checked) {
      newSelectedColoresIds = colores.data.map((color) => color.id);
    } else {
      newSelectedColoresIds = [];
    }

    setSelectedColoresIds(newSelectedColoresIds);
    console.log('newSelectedColoresIds==>')
    console.log(newSelectedColoresIds)
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedColoresIds.indexOf(id);
    let newSelectedColoresIds = [];

    if (selectedIndex === -1) {
      newSelectedColoresIds = newSelectedColoresIds.concat(selectedColoresIds, id);
    } else if (selectedIndex === 0) {
      newSelectedColoresIds = newSelectedColoresIds.concat(selectedColoresIds.slice(1));
    } else if (selectedIndex === selectedColoresIds.length - 1) {
      newSelectedColoresIds = newSelectedColoresIds.concat(selectedColoresIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedColoresIds = newSelectedColoresIds.concat(
        selectedColoresIds.slice(0, selectedIndex),
        selectedColoresIds.slice(selectedIndex + 1)
      );
    }

    setSelectedColoresIds(newSelectedColoresIds);
  };

  const handlePageChange = (event, newPage) => {
    getColores(newPage);
  };

  function onImageChange(picture) {
    setImage(picture[0]);
  }

  return (
    <React.Fragment>
      <Card>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            xs={12}
          >
            <CardHeader
              title="Colores"
            />
            <Grid container justifyContent="flex-end">
              <Button
                color="primary"
                variant="contained"
                style={{marginRight: "15px", marginBottom: "15px"}}
                onClick={() => { setOpenColor(true); }}
              >
                Agregar
              </Button>
            </Grid>
          </Grid>
          {colores!==null && colores.data.length>0 ? 
          <Grid
            item
            xs={12}
          >
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  xs={12}
                >
                  <PerfectScrollbar>
                    <Box minWidth={500}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selectedColoresIds.length === colores.data.length}
                                color="primary"
                                indeterminate={
                                  selectedColoresIds.length > 0
                                  && selectedColoresIds.length < colores.data.length
                                }
                                onChange={handleSelectAll}
                              />
                            </TableCell>
                            <TableCell align="center">
                              Nombre
                            </TableCell>
                            <TableCell align="center">
                              SKU
                            </TableCell>
                            <TableCell align="center">
                            </TableCell>
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {colores.data.map((color) => (
                            <TableRow
                              hover
                              key={color.id}
                              selected={selectedColoresIds.indexOf(color.id) !== -1}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedColoresIds.indexOf(color.id) !== -1}
                                  onChange={(event) => handleSelectOne(event, color.id)}
                                  value="true"
                                />
                              </TableCell>
                              <TableCell align="center">
                                {color.nombre}
                              </TableCell>
                              <TableCell align="center">
                                {color.sku}
                              </TableCell>
                              <TableCell align="center">
                                  {color.imagen ?
                                    <a href={`${process.env.REACT_APP_API_URL}`+color.imagen} target="_blank" rel="noreferrer">
                                        <img style={{ width: 115 }} src={`${process.env.REACT_APP_API_URL}`+color.imagen} alt={color.nombre} />
                                    </a>
                                  :''}   
                              </TableCell>
                              <TableCell align="center">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  <Button onClick={() => { setColorActivo(color);setOpenUpdate(true); }}>Editar</Button>
                                  <Button onClick={() => { setColorActivo(color);setOpen(true); }}>Eliminar</Button>
                                </ButtonGroup>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </PerfectScrollbar>
                </Grid>
                <Grid
                  item
                  xs={12}
                >
                  <Grid container justifyContent="flex-end">
                    <Pagination count={colores.meta.last_page} page={colores.meta.current_page} onChange={handlePageChange} />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
          : ''}
        </Grid>
      </Card>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Confirmación
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            El color '{colorActivo!==null ? colorActivo.nombre : ''}' será eliminado 
            <strong className="block_ctrl">¿Deseas continuar?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={() => { confimDelete(); }} color="primary">
            Si, eliminar.
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openColor} onClose={handleCloseAdd} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            nombre: '',
            sku: ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
            sku: Yup.string().max(50).required()
          })}
          onSubmit={(values) => {
            setDisabled(true);
            var formData = new FormData(); 
            if (image) {
              formData.append('imagen', image, image.name);
            }
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/colores`,
                formData,
                {
                  onUploadProgress: progressEvent => {
                    let prog=Math.round(progressEvent.loaded / progressEvent.total * 100);
                    if (prog!==100) {
                      setProgress(prog);
                    }
                  }
                }
              )
              .then(response => {
                if (response.status===201) {
                    showToastr('success', 'Correcto.', 'Has agregado un nuevo color.', 2500, true, true, 'top-right');
                    setProgress(100);
                    getColores(1);
                    setOpenColor(false);
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setProgress(null);
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Nuevo color"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.sku && errors.sku)}
                              fullWidth
                              helperText={touched.sku && errors.sku}
                              label="SKU"
                              margin="normal"
                              name="sku"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.sku}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            className="mt-3"
                          >
                            <ImageUploader
                              withIcon={true}
                              buttonText='Selecciona una imagen'
                              onChange={onImageChange}
                              imgExtension={['.jpg', '.JPG','.jpeg', '.png', '.webp', '.svg']}
                              maxFileSize={5242880}
                              singleImage={true}
                              withPreview={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAdd} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Agregar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>


      <Dialog open={openUpdate} onClose={handleCloseUpdate} aria-labelledby="form-dialog-title">
        <Formik
          initialValues={{
            nombre: colorActivo!==null ? colorActivo.nombre : '',
            id: colorActivo!==null ? colorActivo.id : '',
            sku: colorActivo!==null ? colorActivo.sku : ''
          }}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            nombre: Yup.string().max(100).required(),
            sku: Yup.string().max(50).required()
          })}
          onSubmit={(values) => {
            setDisabled(true);
            var formData = new FormData();
            if (image) {
              formData.append('imagen', image, image.name);
            }
            Object.entries(values).forEach(val => {
              formData.append(val[0], val[1]);
            });
            return axiosApiInstanceFormData.post(
                `${process.env.REACT_APP_API_URL}v2/colores/update`,
                formData
              )
              .then(response => {
                if (response.status===200) {
                    showToastr('success', 'Correcto.', 'El color ha sido actualizado.', 2500, true, true, 'top-right');
                    getColores(1);
                    setOpenUpdate(false);
                }
                return response;
              })
              .catch(function (error) {
                toastrError(error);
              }).then(function () {
                setImage(null);
                setDisabled(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            setFieldValue
          }) => (
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
            >
              <DialogContent>
                <Grid
                  container
                  spacing={1}
                >
                  <Grid
                    item
                    xs={12}
                  >
                    <CardHeader
                      title="Actualizando color"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                  >
                    <Divider />
                    <CardContent>
                      <Grid
                        item
                        xs={12}
                      >
                        <Grid
                          container
                          spacing={0}
                        >
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.nombre && errors.nombre)}
                              fullWidth
                              helperText={touched.nombre && errors.nombre}
                              label="Nombre"
                              margin="normal"
                              name="nombre"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.nombre}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <TextField
                              error={Boolean(touched.sku && errors.sku)}
                              fullWidth
                              helperText={touched.sku && errors.sku}
                              label="SKU"
                              margin="normal"
                              name="sku"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.sku}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="text-center"
                          >
                            {colorActivo.imagen?
                              <React.Fragment>
                                <span className="block_ctrl">Imagen actual:</span>
                                <Button variant="contained" color="default" href={`${process.env.REACT_APP_API_URL}`+colorActivo.imagen} target="_blank">
                                  <img style={{ width: 115 }} src={`${process.env.REACT_APP_API_URL}`+colorActivo.imagen} alt={colorActivo.nombre} />
                                </Button>
                              </React.Fragment>
                            :''}
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            className="text-center"
                          >
                            <ImageUploader
                              withIcon={true}
                              buttonText='Cambiar imagen'
                              onChange={onImageChange}
                              imgExtension={['.jpg', '.JPG','.jpeg', '.png', '.webp', '.svg']}
                              maxFileSize={5242880}
                              singleImage={true}
                              withPreview={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseUpdate} color="secondary" variant="contained">
                  Cancelar
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={disabled}>
                  Actualizar
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>



    </React.Fragment>
  );

};

Results.propTypes = {
  className: PropTypes.string
};

export default Results;
